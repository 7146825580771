



const NotPage = () => (
    <div >
      this page is not exist .
    </div>
)

export default NotPage;